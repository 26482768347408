.bigDiv{
    background-image: url('../Images/image\ 19.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-top: 3%;
}

.StartingDiv{
    background-color: #240C9B;
    height: 50px;
}

.signInDiv{
    background-color: #fff;
    width: 80%;
    min-height: 80vh;
    display: block;
    margin: auto;
    /* margin-top: 5% !important; */
    padding: 10px;
}
.signInDiv button{
    color: black;
    background-color: #D9D9D9;
    width: 150px;
    padding: 10px;
    border: 1px solid #D9D9D9;
    margin-top: 1%;
    margin-left: 2%;
}
.signInDiv .access{
    font-size: 1.5rem;
    margin-left: 4%;
    margin-top: 3%;
    font-weight: 500;
}
.signInDiv input{
    width: 90%;
    margin-top: 3%;
    margin-left: 5%;
    padding: 10px;
    font-size: 1.4rem;
    outline: none;
    padding-left: 40px;
}
 
.signInDiv .signinBtn{
    width: 300px;
    display: block;
    margin: auto;
    margin-top: 2% !important;
    background-color: #407BFF;
    color: #fff;
    font-size: 1.4rem;
}

.signInDiv .forget {
text-align: center;
font-size: 1.2rem;
color: #0B0B95;
margin-top: 1%;
}

.signInDiv div{
    display: flex;
    gap : 50px;
    margin-top: 5%;
    margin-left: 12%;

}
.signInDiv div .sing {
    position: relative;
    width: 250px;
}
.signInDiv div .sing p{
    position: absolute;
    /* top: 2px; */
    bottom: -20px;
    font-size: 1.8rem;
    font-weight: 600;
}