/** @format */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

.LogoImage {
  width: 100px;
}

.two-sec {
  display: flex;
  width: 90%;
  background-color: #4476cc;
  margin-left: 5%;
  margin-top: 1%;
  gap: 10px;
}

.two-sec div p {
  font-size: 1.4rem;
  color: #fff;
  width: 90%;
  margin-left: 5%;
  margin-top: 1%;
}

.two-sec div .special {
  color: #fff506;
  text-align: end;
}

/* ---------------------------------------------- */
.elipse {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2%;
  margin-top: 2%;
}
.elipse div {
  width: 300px;
  height: 320px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 4px 4px #1e88e5;
  border-radius: 8px;
}

/* ----------------------------------------------------------- */

.two-sec-new {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
}

.two-sec-new .left {
  display: flex;
  gap: 2rem;
  width: 40%;
  text-align: left;
}
.two-sec-new div .upper {
  font-size: 1.4rem;
  margin-top: 10%;
}
.two-sec-new div p {
  width: 400px;
  padding: 10px;
  font-size: 1.2rem;
}
.two-sec-new .right {
  display: flex;
  gap: 2rem;
  width: 40%;
  text-align: left;
}

/* -------------------------------------------- */

.five-sec {
  display: grid;
  grid-template-columns: repeat(5, 2fr);
  margin-bottom: 4%;
  margin-left: 2%;
  justify-content: space-evenly;
  grid-gap: 10px;
}
.five-sec div {
  padding: 10px;
  width: 300px;
  height: 320px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: relative;
}
.five-sec div p {
  font-size: 1.4rem;
}
.five-sec div img {
  margin-top: 40px !important;
  text-align: center;
  display: block;
  margin: auto;
}
.five-sec div button {
  width: 95%;
  padding: 10px;
  font-size: 1.2rem;
  background-color: #4c68f5;
  border-radius: 4px;
  border: 1px solid #4c68f5;
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

/* ---------------------------------------------------- */
.newBan {
  background-image: url("./Images/Group\ 7993.png");
  min-height: 20vh;
  width: 96%;
  margin-left: 2%;
  background-position: center;
  margin-top: 3%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 2%;
  padding: 10px;
  text-align: center;
  position: relative;
}
.newBan p {
  font-size: 1.5rem;
  color: #fff;
}
.newBan div {
  display: flex;
  justify-content: center;
  gap: 20px;
  bottom: 10px;
  left: 40%;
  position: absolute;
}
.newBan div button {
  padding: 10px;
  font-size: 1.3rem;
  border: 1px solid #fff;
}

/* ----------------------------------------------------------------- */
.footer {
  background-color: #4c68f5;
  padding: 10px;
  color: #fff;
}
.footer .head {
  font-size: 1.9rem;
  color: #fff;
  font-weight: 400;
  margin-left: 2%;
}
.footer .big {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 1%;
}

.footer .big .first {
  margin-left: 3%;
}
.footer .big .first img {
  margin-top: 10%;
}

.footer .big .second .dwn {
  font-size: 1.2rem !important;
  max-width: 300px;
}

.footer .big .third .nn {
  font-size: 1.2rem !important;
  max-width: 300px;
}

/* -------------------------------------------------------- */

/* Chart Screen */
.chart-two-sec {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 5%;
  margin-top: 2%;
}
.chart-two-sec div p {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.chart-two-sec .double-two {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}
.chart-two-sec .double-two p {
  font-weight: 400;
}

.divider {
  display: flex;
  margin-top: 5%;
  width: 90%;
  margin-left: 5%;
  justify-content: space-between;
}
.divider div {
  display: flex;
  gap: 10px;
}

.chart-three-sec {
  display: flex;
  width: 80%;
  margin-left: 10%;
  margin-top: 3%;
  justify-content: space-between;
  margin-bottom: 5%;
}
.chart-three-sec div p {
  font-size: 1.6rem;
  margin-bottom: 10%;
  font-weight: 500;
}

.threesec-two {
  display: flex;
  width: 80%;
  margin-bottom: 5%;
  margin-left: 10%;
  justify-content: space-between;
  gap: 5%;
}
.threesec-two .left {
  display: flex;
  border: 1px solid black;
  width: 300px;
  gap: 20px;
  height: 70px;
  padding: 5px;
}
.threesec-two .left p {
  font-size: 1.5rem;
  padding-top: 12px;
  padding-left: 15px;
}
.threesec-two .left img {
  width: 40px;
  height: 40px;
  margin-top: 12px;
}
.threesec-two .mid {
  display: flex;
  height: 250px;
}
.threesec-two .mid .first {
  background-color: #407bff;
  width: 10px;
}
.threesec-two .mid .two h6 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 10px;
}
.threesec-two .mid .two p {
  font-size: 1.4rem;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.threesec-two .mid .two div {
  background-color: #407bff;
  height: 10px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.threesec-two .right p {
  font-size: 1.6rem;
  margin-top: 80px;
}

.emptyDiv {
  height: 100px;
  background-color: #0065fc;
  width: 100%;
  margin-bottom: 1%;
}

.mailHead {
  display: flex;
  gap: 20px;
  margin-left: 5%;
  font-size: 23px;
}
.mailHead span {
  background-color: #008000;
  height: 40px;
  text-align: center;
  color: #fff;
  font-size: 26px;
  width: 40px;
}

.mailTwo-sec {
  display: flex;
  margin-left: 5%;
  width: 80%;
  gap: 20px;
  justify-content: space-between;
  margin-top: 3%;
}
.mailTwo-sec .empty {
  background-color: #d9d9d9;
  width: 100px;
  height: 40px;
  border-radius: 100%;
  margin-top: 10px;
}

.mailTwo-sec .para {
  background-color: #d9d9d9;
  padding: 10px;
  font-weight: 600;
  font-style: italic;
}

.AboutUs {
  min-height: 463.25px;
  width: 900px;
  max-width: min(100%, 900px);
  background: #0d53d8;
  display: block;
  margin: auto;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  color: #fff;
  padding: 70px 70px;
}
.AboutUs .Head{
  font-size: 2rem;
  font-weight: 600;
}
.AboutUs .content{
  font-size: 20px;
  font-weight: normal;
}

/* Responsive */
@media (max-width: 768px) {
  .two-sec {
    display: block;
  }
  .two-sec img {
    display: block;
    margin: auto;
  }
  .two-sec .special {
    padding-bottom: 40px;
  }

  .elipse {
    display: block;
  }
  .elipse div {
    display: block;
    margin: auto;
    margin-top: 4% !important;
  }

  .two-sec-new {
    display: block;
  }
  .two-sec-new .left {
    display: block;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .two-sec-new .right {
    display: block;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .two-sec-new div p {
    width: 100%;
  }

  .five-sec {
    display: block;
  }
  .five-sec div {
    display: block;
    margin: auto;
    margin-top: 4%;
  }
  .newBan {
    min-height: 20vh;
  }
  .newBan div {
    position: relative;
    margin-top: 8%;
    justify-content: start;
    left: 10px;
  }

  .footer .big {
    display: block;
    text-align: center;
  }
  .footer .big .second .dwn {
    max-width: 90%;
    text-align: center;
    display: block;
    margin: auto;
  }
  .footer .big .third .nn {
    max-width: 90%;
    text-align: center;
    display: block;
    margin: auto;
    margin-top: 1% !important;
  }
  .footer .big div {
    margin-top: 5%;
  }
  .chart-two-sec {
    display: block;
  }
  .chart-two-sec img {
    width: 400px;
  }
  .chart-three-sec {
    display: block;
    margin: auto;
  }
  .chart-three-sec div {
    display: block;
    width: 100%;

    margin: auto;
  }
  .threesec-two {
    display: block;
    margin: auto;
  }
  .threesec-two .mid {
    margin-top: 5%;
  }
  .threesec-two .right {
    margin-top: 2%;
    margin-bottom: 5%;
  }
}
